import 'whatwg-fetch';
import * as helper from './helper';
import es6Promise from 'es6-promise';
// import adv from '../assets/adv.png';
// import slogan from '../assets/slogan.png';
// import join from '../assets/join.png';
// import filmBg from '../assets/film_bg.png';
import $ from '../assets/jq/jquery-3.4.1.min.js';

window.Promise = es6Promise.Promise;

$(function () {
  $('#contact').load('components/footer.html');
  $('#job_footer').load('components/jobFooter.html');
});
// function handleFileFetch (resp) {
//   if (resp.ok) {
//     return resp.blob();
//   }
//   throw new Error('资源加载失败');
// }
// 判断浏览器是否是ie11以下
const browser = helper.IEVersion();
if (browser > 0 && browser !== 11) {
  window.location.href('../ie.html');
}


let playing = false;
Promise.all([
  // window.fetch(adv).then(handleFileFetch),
  // window.fetch(slogan).then(handleFileFetch),
  // window.fetch(join).then(handleFileFetch),
  // window.fetch(filmBg).then(handleFileFetch)
]).then(() => {
  // $('#page-header').load('../header.html');
  $('#page-header').load('components/header.html');
}).catch(() => {
  console.log('error: 资源加载失败');
});

// const headers = document.getElementById('header');
// const menus = headers.getElementsByTagName('li');
// const nav = headers.getElementsByTagName('a');
// 获取当前页面pathName
const menuHref = window.location.href;
const menuPath = menuHref.split('/').pop();
// 在服务器部署时访问域名会直接到index页面
const nowPath = menuPath.split('.').shift() || 'index';

document.onreadystatechange = () => {
  if (document.readyState === 'interactive') {
    return false;
  }
  // const firstPage = document.getElementById('firstPage');
  const videoAll = document.getElementById('videoType');
  if (nowPath === 'index') {
    // if (!helper.isMobile()) {
    //   window.fetch('../assets/beian.png').then((resp) => {
    //     debugger
    //     if (resp.ok) {
    //       return resp.blob();
    //     }
    //     throw new Error('资源加载失败');
    //   }).then(() => {
    //     playing = true;
    //     handlePlay();
    //   }).catch(() => {
    //     console.log('error: 视频加载失败');
    //   });
    // }
    document.getElementById('video').addEventListener('ended', e => {
      playing = false;
      helper.removeClass(videoAll, 'page-first_playing');
    });

    document.getElementById('video').addEventListener('playing', e => {
      helper.addClass(videoAll, 'page-first_playing');
    });
    // 点击视频暂停播放
    document.getElementById('video').addEventListener('click', e => {
      handlePause();
      helper.removeClass(videoAll, 'page-first_playing');
    });

    document.getElementById('player').addEventListener('click', e => {
      playing = true;
      handlePlay();
    });
    document.addEventListener('scroll', () => {
      // const { clientHeight } = document.documentElement;
      if (window.scrollY > 600 && window.scrollY < 1600) {
        playing = true;
        handlePlay();
      } else {
        playing = false;
        handlePause();
      }
    });
  };
  // 浏览器大小改变
  // window.addEventListener('resize', () => handleWindowResize(firstPage));


  // 抽屉
  document.getElementById('openDrawerBtn').addEventListener('click', () => {
    console.log('mini');
    // handlePause();
    handleOpen();
  });

  // 关闭抽屉
  document.getElementById('closeDrawerBtn').addEventListener('click', () => {
    // handlePlay();
    handleClose();
  });
};

/**
 * 打开抽屉
 */
function handleOpen () {
  let drawer = document.getElementById('drawer');
  helper.addClass(drawer, 'page-drawer_opened');
  document.documentElement.style.height = '100%';
  document.documentElement.style.overflow = 'hidden';
  drawer = null;
}

// /**
//  * 关闭抽屉
//  */
function handleClose () {
  let drawer = document.getElementById('drawer');
  helper.removeClass(drawer, 'page-drawer_opened');
  document.documentElement.style.height = '';
  document.documentElement.style.overflow = '';
  drawer = null;
}

function handlePlay () {
  let video = document.getElementById('video');
  const videoI = document.getElementById('videoType');
  // debugger
  if (playing) {
    video.play();
    videoI.style.background = '#ffffff';
  }
  // if (playing && !helper.isMobile()) {
  //   video.play();
  // }

  video = null;
}

function handlePause () {
  let video = document.getElementById('video');
  video.pause();
  video = null;
}


/**
 * 处理页面高度
 * @param target
 */
// function handleWindowResize (target) {
//   const { clientHeight, clientWidth } = document.documentElement;
//   target.style.height = `${clientHeight}px`;

//   if (clientWidth > 1000) {
//     handleClose();
//   }
// }

const phoneArr = { idxOne: 'phoneOne', idxTwo: 'phoneTwo', idxThree: 'phoneThree', idxFour: 'phoneFour' };
$(function () {
  $('.defaultSty').on({
    mouseenter: function () {
      $(this).addClass('hoverSty').siblings().removeClass('hoverSty');
      $('#allPhone').removeClass();
      $('#allPhone').addClass(`phoneImg ${phoneArr[$(this)[0].id]}`);
    }
    // mouseleave: function () {
    //   // 控制手机显示
    //   // $('#allPhone').removeClass();
    //   // $('#allPhone').addClass(`phoneImg ${phoneArr[$(this)[0].id]}`);
    // }
  });
  $('.defaultSty').eq(0).addClass('hoverSty');
});

